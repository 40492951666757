const constants = {
    PREV: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
    WORK_WEEK: 'work_week',
    MONTH: 'month',
    WEEK: 'week',
    ALL: 'todos',
    MORNING: 'MATUTINO',
    AFTERNOON: 'VESPERTINO',
    NIGHT: 'NOCTURNO'
}

export default constants;