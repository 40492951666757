
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar';

import CustomToolbar from 'Components/CustomToolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)
moment.lang('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
}
);

const MyCalendar = ({ myEventsList, handleEditInfo, setStartDate }) => {
    const event = ({ event }) => {
        return (
            <span>
                <strong>
                    {event.title}
                </strong>
                {event.desc && (':  ' + event.desc)}
            </span>
        )
    }
    const onPressEvent = (event) => {
        handleEditInfo(event)
    }
    const handleNavigate = (event) => {
        console.log(`event`, event);
        setStartDate(moment(event).startOf("month").format("YYYY-MM-DD"))
    }

    return (
        <>
            <Calendar
                events={myEventsList}
                isValid={'dsds'}
                drilldownView="agenda"
                views={['month', 'agenda', 'week']}
                localizer={localizer}
                step={30}
                onSelectEvent={onPressEvent}
                onNavigate={handleNavigate}
                components={{
                    event: event,
                    toolbar: CustomToolbar,
                }}
                style={{ height: 700 }}

            />
        </>
    )
};


export default MyCalendar