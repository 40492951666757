import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { AiOutlineClose, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
    Wrapper,
    Backdrop,
    Container,
    Content,
    Header,
    CloseButton,
    Body,
    Footer,
} from './Modal.styled';
import Colors from 'Utils/Colors';

export const SIZES = ['320px', '380px', '480px', '680px', '768px', '900px'];

export const LEVELS = ['base', 'grey', 'warning', 'error'];

const LEVEL_STYLES = {
    base: {
        headerBackgroundColor: 'base',
        headerTextColor: 'white',
    },
    grey: {
        headerBackgroundColor: 'backgroundGrey_2',
        headerTextColor: 'darkGrey_2',
    },
    warning: {
        headerBackgroundColor: 'warning',
        headerTextColor: 'darkGrey',
    },
    error: {
        headerBackgroundColor: 'error',
        headerTextColor: 'white',
    },
};

export const Portal = ({ children, container, isOpen, onHide, isStatic }) => {
    const handleKeyDown = (event) => {
        if (event.keyCode === 27) onHide();
    };


    useEffect(() => {
        if (!isOpen || !container || isStatic) return () => null;

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, container]);
    return isOpen ? createPortal(children, document.body) : null;
};

const Modal = ({
    'data-testid': testId,
    title,
    body,
    footer,
    isOpen,
    isCreate,
    isNocturn,
    onClose,
    editTrusted,
    deleteGuard,
    validationRole,
    size,
    isStatic,
    level,
    padding,
    bodyBackground,
    clearData,
    hideCloseButton,
}) => {


    const container = useRef(null);
    // Handle fadeIn/fadeOut animation
    const [isShow, setIsShow] = useState(true);

    const onHide = () => {
        setIsShow(false);
        clearData()
    };

    return (
        <Portal container={container} isOpen={isOpen} onHide={onHide} isStatic={isStatic}>
            <Wrapper data-testid={testId}>
                <Backdrop isShow={isShow} />
                <Container>
                    <Content
                        ref={container}
                        isShow={isShow}
                        size={size}
                        bodyBackground={bodyBackground}
                        onAnimationEnd={() => {
                            if (!isShow) {
                                onClose();
                                setIsShow(true);
                            }
                        }}
                    >
                        {title && (
                            <Header levelStyles={LEVEL_STYLES[level]}>
                                <div />
                                {title}
                                {!hideCloseButton && (
                                    <>

                                        {!isCreate && validationRole &&
                                            <>
                                                <CloseButton
                                                    levelStyles={LEVEL_STYLES[level]}
                                                    type="button"
                                                    onClick={() => deleteGuard()}
                                                    data-testid={`${testId}-close-button`}
                                                >
                                                    <AiOutlineDelete color={Colors.error} />
                                                </CloseButton>
                                                {!isNocturn &&
                                                    <CloseButton
                                                        levelStyles={LEVEL_STYLES[level]}
                                                        type="button"
                                                        onClick={editTrusted}
                                                        data-testid={`${testId}-close-button`}
                                                    >
                                                        <AiOutlineEdit />
                                                    </CloseButton>}

                                            </>
                                        }
                                        <CloseButton
                                            levelStyles={LEVEL_STYLES[level]}
                                            type="button"
                                            onClick={onHide}
                                            data-testid={`${testId}-close-button`}
                                        >
                                            <AiOutlineClose />
                                        </CloseButton>
                                    </>
                                )}
                            </Header>
                        )}
                        <Body padding={padding}>{body}</Body>
                        {footer && <Footer>{footer}</Footer>}
                    </Content>
                </Container>
            </Wrapper>
        </Portal >
    );
};

Modal.defaultProps = {
    footer: null,
    isOpen: false,
    'data-testid': '',
    size: '768px',
    isStatic: false,
    level: 'base',
    padding: 0.625,
    bodyBackground: 'white',
    title: null,
    hideCloseButton: false,
    isCreate: false,
    isNocturn: false,
    validationRole: false,
    editTrusted: () => { },
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    'data-testid': PropTypes.string,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    size: PropTypes.string,
    isStatic: PropTypes.bool,
    level: PropTypes.oneOf(LEVELS),
    padding: PropTypes.number,
    bodyBackground: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    isCreate: PropTypes.bool,
    isNocturn: PropTypes.bool,
    editTrusted: PropTypes.func,
    validationRole: PropTypes.bool
};

export default Modal;