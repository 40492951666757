import { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import constants from 'Utils/constants';
import PropTypes from 'prop-types';

import {
    WrapperCustomToolbar,
    WrapperIconsLeft,
    WrapperIconsRigth,
    WrapperIconsCenter,
    Button,
    Label,
    ButtonToday
} from './CustomToolbar.styled';


const CustomToolbar = (props) => {
    const { onNavigate, label, onView, date, localizer, view } = props
    const [active, setActive] = useState(true)
    return (
        <WrapperCustomToolbar >
            <WrapperIconsLeft>
                <Button onClick={() => { onNavigate(constants.PREV) }}>
                    <AiOutlineLeft />
                </Button>
                <Button onClick={() => onNavigate(constants.NEXT)}>
                    <AiOutlineRight />
                </Button>
                <ButtonToday onClick={() => onNavigate(constants.TODAY)} color="midGrey_2">
                    Hoy
                </ButtonToday>
            </WrapperIconsLeft>
            <WrapperIconsCenter >

                {view === constants.MONTH ?
                    <Label >{`${label}`}</Label>
                    :
                    <Label >{`${label}, ${localizer.format(date, 'YYYY')}`}</Label>
                }

            </WrapperIconsCenter>
            <WrapperIconsRigth>
                <Button transparent active={view === constants.MONTH} onClick={() => {
                    setActive(true)
                    onView(constants.MONTH)
                }}><span>Mes</span></Button>
                <Button transparent active={view === constants.WEEK} onClick={() => {
                    onView(constants.WEEK)
                    setActive(true)
                }}><span>Semana</span></Button>
            </WrapperIconsRigth>
        </WrapperCustomToolbar>
    )
}

CustomToolbar.propTypes = {
    onNavigate: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    onView: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    localizer: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired
};


export default CustomToolbar;