import moment from 'moment';

export const lowerCase = (str) => {
    return str.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

export const generateStartDate = ({ date_guard, guard_shift }) => {
    let date;
    switch (guard_shift) {
        case 'MATUTINO':
            date = moment(date_guard).format('YYYY-MM-DD 10:00:00');
            break;
        case 'VESPERTINO':
            date = moment(date_guard).format('YYYY-MM-DD 14:31:00');
            break;
        case 'NOCTURNO':
            date = moment(date_guard).format('YYYY-MM-DD 19:00:01');
            break;
        default:
            break;
    }
    return new Date(date)
}

export const generateEndDate = ({ date_guard, guard_shift }) => {

    let date;
    switch (guard_shift) {
        case 'MATUTINO':
            date = moment(date_guard).format('YYYY-MM-DD 14:30:59');
            break;
        case 'VESPERTINO':
            date = moment(date_guard).format('YYYY-MM-DD 19:00:00');
            break;
        case 'NOCTURNO':
            date = moment(date_guard).format('YYYY-MM-DD 09:59:59');
            break;
        default:
            break;
    }
    return new Date(date)
}