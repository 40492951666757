
import { useState } from 'react'
import moment from 'moment';

import Modal from 'Components/Modal';
import PropTypes from 'prop-types';
import { lowerCase } from 'Utils/Utils';
import constants from 'Utils/constants';
import {
    BodyContainer,
    WrapperSelect,
    Select,
    WrapperTurn,
    Square,
    Label,
    Button,
    WrapperButton,
    Picker,
    CancelButton,
    MessageWrapper
} from './RendernModal.styled';
import "react-datepicker/dist/react-datepicker.css";


const RenderModal = ({
    isOpen,
    isEdit,
    setIsOpen,
    editConfirm,
    developments,
    advisors,
    setAddTurn,
    addTurn,
    handleSendRequest,
    clearData,
    tittleModal,
    isCreate,
    editTrusted,
    message,
    role,
    deleteGuard,
    handleDeleteGuard,
    handleEditGuard,
    handleConfirmEdit,
    handleSendDeleteGuard
}) => {
    const [startDate] = useState(new Date());
    const [date, setDate] = useState(new Date())
    const handleSudmit = (e) => {
        setAddTurn({
            ...addTurn,
            'guard_shift': e
        })
    }
    const handleSudmitDev = ({ target: { value }
    }) => {

        const filter = developments?.filter((item) => item.VALUE == value)
        setAddTurn({
            ...addTurn,
            'development': filter[0].VALUE,
            'development_bitrix_id': filter[0].ID,
        })
    }
    const handleSudmitAds = ({ target: { value }
    }) => {
        const filter = advisors?.filter((item) => item.ID == value)
        setAddTurn({
            ...addTurn,
            'name': filter[0].NAME,
            'last_name': filter[0].LAST_NAME,
            'advisor_bitrix_id': filter[0].ID
        })

    }
    const handleSudmitDate = (date) => {
        setDate(date)
        setAddTurn({
            ...addTurn,
            'date_guard': moment(date).format('YYYY-MM-DD')
        })
    }
    const validationRole = role === "admin" ? true : false;
    let disabledInput;
    if (validationRole) {
        disabledInput = !isCreate && validationRole
    } else {
        disabledInput = !isCreate
    }

    const Body = () => {
        return (
            <BodyContainer>
                <WrapperSelect>
                    <div>
                        <Select disabled={disabledInput} onChange={(e) => handleSudmitDev(e)} >
                            <option value={addTurn.development}>{addTurn.development ? addTurn.development : 'Desarrollos'}</option>
                            {developments?.map((item) =>
                                <option
                                    key={item.ID}
                                    value={item.VALUE}>
                                    {item.VALUE}
                                </option>)}
                        </Select>
                    </div>
                    <div>
                        <Select disabled={disabledInput} onChange={(e) => handleSudmitAds(e)}>
                            <option value={`${addTurn.name} ${addTurn.last_name}`}>
                                {addTurn.name ? lowerCase(`${addTurn.name} ${addTurn.last_name}`) : 'Usuario'}
                            </option>
                            {advisors?.map((item) =>
                                <option
                                    key={item.ID}
                                    value={item.ID}>
                                    {lowerCase(`${item.NAME} ${item.LAST_NAME}`)}
                                </option>
                            )}
                        </Select>
                    </div>
                    <div>
                        <Picker
                            readOnly={disabledInput}
                            selected={addTurn.date_guard === '' ? date : new Date(addTurn.date_guard.replace(/-/g, '\/'))}
                            onChange={(date) => handleSudmitDate(date)} />
                    </div>
                </WrapperSelect>

                <WrapperTurn>
                    {addTurn.guard_shift === "NOCTURNO" ?
                        <Square
                            onClick={() => isCreate ? handleSudmit(constants.NIGHT) : console.log}
                            active={addTurn.guard_shift === constants.MORNING} > <Label>NOCTURNO</Label> </Square>
                        :
                        <>
                            <Square
                                onClick={() => isCreate ? handleSudmit(constants.MORNING) : console.log}
                                active={addTurn.guard_shift === constants.MORNING} > <Label>MATUTINO</Label> </Square>
                            <Square
                                onClick={() => isCreate ? handleSudmit(constants.AFTERNOON) : console.log}
                                active={addTurn.guard_shift === constants.AFTERNOON}> <Label>VESPERTINO</Label> </Square>
                        </>
                    }

                </WrapperTurn>
                <MessageWrapper>{message}</MessageWrapper>
                {validationRole &&
                    <WrapperButton>
                        <CancelButton onClick={() => {
                            setIsOpen(false)
                            clearData()
                            setDate(new Date())
                        }}>
                            Cancelar
                        </CancelButton>
                        {deleteGuard &&
                            <Button onClick={handleSendDeleteGuard}>
                                Eliminar
                            </Button>
                        }
                        {addTurn?.guard_shift !== constants.NIGHT && !deleteGuard && !isEdit &&
                            <Button onClick={handleSendRequest}>
                                Guardar
                            </Button>
                        }

                        {isEdit &&
                            <Button onClick={() => editConfirm ? handleConfirmEdit() : handleEditGuard()}>
                                {editConfirm ? 'Guardar edicion' : 'Editar'}
                            </Button>
                        }


                    </WrapperButton>
                }
            </BodyContainer>
        )
    }

    return (
        <div className="App">
            <Modal title={tittleModal} body={<Body />}
                isOpen={isOpen}
                onClose={setIsOpen}
                isCreate={isCreate}
                isNocturn={addTurn?.guard_shift === constants.NIGHT}
                editTrusted={editTrusted}
                deleteGuard={handleDeleteGuard}
                clearData={clearData}
                validationRole={validationRole} />
        </div>
    );
}
RenderModal.defaultProps = {
    setIsOpen: () => { },
    setTurn: () => { },
    develoments: [],
    advisors: [],
    turn: false
}

RenderModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    developments: PropTypes.array,
    advisors: PropTypes.array,
    setIsOpen: PropTypes.func,
    setTurn: PropTypes.func,
    turn: PropTypes.bool,
};
export default RenderModal;
