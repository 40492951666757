import { all } from 'redux-saga/effects'
import {
    eventSaga,
    eventSagaDepartaments,
    eventSagaAdvisors,
    eventSagaGuards,
    eventSagaPostAdvisoryByGuard,
    eventSagaEditAdvisoryByGuard,
    eventSagaCheckGuard,
    eventSagaDeleteAdvisoryByGuard
} from './eventSagas'

export default function* rootSaga() {
    yield all([
        eventSaga(),
        eventSagaDepartaments(),
        eventSagaAdvisors(),
        eventSagaGuards(),
        eventSagaPostAdvisoryByGuard(),
        eventSagaEditAdvisoryByGuard(),
        eventSagaCheckGuard(),
        eventSagaDeleteAdvisoryByGuard(),
    ])
}