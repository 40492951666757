import axios from 'axios';
import Home from './Views/Home';

axios.interceptors.request.use(
  async (axiosConfig) => {
    axiosConfig.baseURL = 'https://api-calendario.idex.cc/api/v1/';

    axiosConfig.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'A235BC355A123FE5A47583EC59FD4',
    };

    return axiosConfig;
  },
  (error) => {
    // console.error(error);
    return Promise.reject(error);
  }
);


function App() {
  return (
    <Home />
  );
}

export default App;
