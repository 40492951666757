import styled from 'styled-components';
import colors from 'Utils/Colors';
import DatePicker from "react-datepicker";

const BodyContainer = styled.div`
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
`;


const Footer = styled.div`
    width: 100%;
    display: flex;
    padding: 1rem;
`;

const WrapperSelect = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
`;

const Square = styled.div`
    display: flex;
    width: 6rem;
    height: 6rem;
    border: 3px solid ${({ active }) => active ? colors.error : colors.darkGrey};
    border-radius: 0.625rem;
    margin: 1rem;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
    padding: 1rem;
    background-color: ${colors.error};
    color: ${colors.white};
    border-radius: 0.625rem;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    margin: 1rem;
`;
const CancelButton = styled.button`
    padding: 1rem;
    background-color: ${colors.darkGrey};
    color: ${colors.white};
    border-radius: 0.625rem;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    margin-left: 1rem;
`;

const WrapperTurn = styled.div`
    display: flex;
    justify-content: center;
`;
const Label = styled.label`
    font-size: 0.925rem;
    color: ${colors.label}
`;

const Select = styled.select`
    width: 14rem;
    height: 2.25rem;
    font-size: 0.925rem;
    border-radius: 0.625rem;
    color: ${colors.label}
`;
const Picker = styled(DatePicker)`
    height: 2rem;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
`;
const WrapperButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MessageWrapper = styled.div`
    text-align: center;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
`

export {
    BodyContainer,
    Footer,
    WrapperSelect,
    Select,
    WrapperTurn,
    Square,
    Label,
    Button,
    Picker,
    WrapperButton,
    CancelButton,
    MessageWrapper
};

