import styled from 'styled-components';

const WrapperLoader = styled.div`
    min-height: 110px;
    position: relative;
`;

const LoaderOverDelai = styled.div`
    align-items: center;
    background-color: $primary;
    background-color: rgba(75, 54, 149, 0.15);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 1000;
`;
const Image = styled.img`
    animation: blink normal 2s infinite ease-in-out;
    background-color: $dark;
    // border-radius: 50%;
    padding: 5px;
    z-index: 2000;
`;


export {
    WrapperLoader,
    LoaderOverDelai,
    Image
}