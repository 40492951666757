
import React from 'react';
import { AiOutlinePlusCircle } from "react-icons/ai";
import PropTypes from 'prop-types';
import {
    Wrapper,
    WrapperSelect,
    WrapperTitle,
    WrapperButton,
    Select,
    Button,
    WrapperTextIcon,
    Text
} from './Header.styled'
import constants from 'Utils/constants';


const Header = ({
    developments,
    setIsOpen,
    setDevelopment,
    setTittleModal,
    clearData,
    setIsCreate,
    role,
}) => {
    const change = (e) => {
        if (e.target.value === constants.ALL) {
            setDevelopment('')
        } else {
            setDevelopment(e.target.value)
        }
    }
    return (
        <Wrapper role={role == "admin"}>
            <WrapperSelect>
                <Select onChange={(e) => change(e)}>
                    <option value="todos">TODOS LOS DESARROLLOS</option>
                    {developments.map((item) => <option
                        key={item.ID}
                        value={item.value}
                    >{item.VALUE}</option>)}
                </Select>
            </WrapperSelect>
            <WrapperTitle>
                <div>GUARDIAS</div>
            </WrapperTitle>
            {role == 'admin' &&
                <WrapperButton>
                    <Button transparent onClick={() => {
                        setIsOpen(true)
                        setTittleModal('NUEVA GUARDIA');
                        clearData();
                        setIsCreate(true)
                    }}>
                        <WrapperTextIcon>
                            <Text>AGENDAR</Text> <AiOutlinePlusCircle size={30} />
                        </WrapperTextIcon>
                    </Button>
                </WrapperButton>}

        </Wrapper>
    )
};

Header.defaultProps = {
    setIsOpen: () => { },
    developments: []
}

Header.propTypes = {
    developments: PropTypes.array,
    setIsOpen: PropTypes.func,
};


export default Header