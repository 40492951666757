export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';
export const GET_EVENTS_REQUESTED = 'GET_EVENTS_REQUESTED';


// GET DEVELOPMENTS
export const GET_DEVELOPMENTS = 'GET_DEVELOPMENTS';
export const GET_DEVELOPMENTS_SUCCESS = 'GET_DEVELOPMENTS_SUCCESS';
export const GET_DEVELOPMENTS_FAILED = 'GET_DEVELOPMENTS_FAILED';
export const GET_DEVELOPMENTS_REQUESTED = 'GET_DEVELOPMENTS_REQUESTED';


// GET ADVISORS
export const GET_ADVISORS = 'GET_ADVISORS';
export const GET_ADVISORS_SUCCESS = 'GET_ADVISORS_SUCCESS';
export const GET_ADVISORS_FAILED = 'GET_ADVISORS_FAILED';
export const GET_ADVISORS_REQUESTED = 'GET_ADVISORS_REQUESTED';

// GET GUARDS
export const GET_GUARDS = 'GET_GUARDS';
export const GET_GUARDS_SUCCESS = 'GET_GUARDS_SUCCESS';
export const GET_GUARDS_FAILED = 'GET_GUARDS_FAILED';
export const GET_GUARDS_REQUESTED = 'GET_GUARDS_REQUESTED';

// GET ADVISORY
export const POST_ADVISORY_GUARD = 'POST_ADVISORY_GUARD';
export const POST_ADVISORY_GUARD_SUCCESS = 'POST_ADVISORY_GUARD_SUCCESS';
export const POST_ADVISORY_GUARD_FAILED = 'POST_ADVISORY_GUARD_FAILED';
export const POST_ADVISORY_GUARD_REQUESTED = 'POST_ADVISORY_GUARD_REQUESTED';


// EDIT GUARDS
export const EDIT_ADVISORY_GUARD = 'EDIT_ADVISORY_GUARD';
export const EDIT_ADVISORY_GUARD_SUCCESS = 'EDIT_ADVISORY_GUARD_SUCCESS';
export const EDIT_ADVISORY_GUARD_FAILED = 'EDIT_ADVISORY_GUARD_FAILED';
export const EDIT_ADVISORY_GUARD_REQUESTED = 'EDIT_ADVISORY_GUARD_REQUESTED';


// CHECK GUARDS
export const CHECK_GUARD = 'EDIT_ADVISORY_GUARD';
export const CHECK_GUARD_SUCCESS = 'CHECK_GUARD_SUCCESS';
export const CHECK_GUARD_FAILED = 'CHECK_GUARD_FAILED';
export const CHECK_GUARD_REQUESTED = 'CHECK_GUARD_REQUESTED';

// DELETE GUARDS
export const DELETE_ADVISORY_GUARD = 'DELETE_ADVISORY_GUARD';
export const DELETE_ADVISORY_GUARD_SUCCESS = 'DELETE_ADVISORY_GUARD_SUCCESS';
export const DELETE_ADVISORY_GUARD_FAILED = 'DELETE_ADVISORY_GUARD_FAILED';
export const DELETE_ADVISORY_GUARD_REQUESTED = 'DELETE_ADVISORY_GUARD_REQUESTED';


export const RESET_STATE = 'RESET_STATE';

