import styled from 'styled-components';
import colors from 'Utils/Colors'


const Wrapper = styled.div`
  display: flex;
  ${({ role }) => role ? 'justify-content: space-between;' : 'flex-direction:row'}
  padding: 1rem;
`;
const WrapperSelect = styled.div`
  display: flex;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  ${({ role }) => role ? '' : 'flex: 1'}
`;
const WrapperButton = styled.div`
  display: flex;
`;
const WrapperTextIcon = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`;

const Select = styled.select`
    width: 14rem;
    height: 2.25rem;
    font-size: 0.925rem;
    border-radius: 0.625rem;
    color: ${colors.label}
`;
const Text = styled.span`
    margin-right: 0.625rem;    
`;


const Button = styled.button`
    padding: 0.625rem;
    background-color: ${({ active }) => active ? colors.darkGrey : colors.error};
    border-radius: 0.625rem;
    color: ${colors.white};
    border-color: ${({ transparent }) => transparent ? 'transparent' : colors.darkGrey};
    font-size: 1rem;
    font-weight: bold;
`;
export {
  Wrapper,
  WrapperSelect,
  WrapperTitle,
  WrapperButton,
  Select,
  Button,
  WrapperTextIcon,
  Text
};

