import * as type from '../types';

export function getEvents(events) {
    return {
        type: type.GET_EVENTS_REQUESTED,
        payload: events
    }
}

export function getDevelopments(developments) {
    return {
        type: type.GET_DEVELOPMENTS_REQUESTED,
        payload: developments
    }
}

export function getAdvisors(advisors) {
    return {
        type: type.GET_ADVISORS_REQUESTED,
        payload: advisors
    }
}

export function getGuards(guards) {
    return {
        type: type.GET_GUARDS_REQUESTED,
        payload: guards
    }
}

export function postAdvisoryByGuard(guards) {
    return {
        type: type.POST_ADVISORY_GUARD_REQUESTED,
        payload: guards
    }
}

export function editAdvisoryByGuard(guards) {
    return {
        type: type.EDIT_ADVISORY_GUARD_REQUESTED,
        payload: guards
    }
}

export function deleteAdvisoryByGuard(guards) {
    return {
        type: type.DELETE_ADVISORY_GUARD_REQUESTED,
        payload: guards
    }
}

export function checkExistGuard(checkGuard) {
    return {
        type: type.CHECK_GUARD_REQUESTED,
        payload: checkGuard
    }
}

export function resetState(reset) {
    console.log(`reset`, reset);
    return {
        type: type.CHECK_GUARD_REQUESTED,
        payload: reset
    }
}


