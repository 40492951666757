import axios from "axios";

const getApi = async () => {
  try {
    const {
      response: { data },
    } = await axios.get("advisory-guard");

    return data;
  } catch (error) {
    throw error;
  }
};

const getDevelopments = async () => {
  try {
    const response = await axios.get("developments");
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getAdvisors = async () => {
  try {
    const response = await axios.get("advisors");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getGuards = async (payload) => {
  try {
    const response = await axios.get("guards", {
      params: payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createAdvisorByGuard = async (payload) => {
  try {
    const response = await axios.post("advisory-guard", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const editAdvisorByGuard = async (payload) => {
  try {
    const response = await axios.put(
      `advisory-guard/${payload.deleted_guard_id}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const checkExistGuard = async (payload) => {
  try {
    const response = await axios.post("check-guard", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteAdvisorByGuard = async (payload) => {
  try {
    const response = await axios.delete(`advisory-guard/${payload.id}`, {
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  getDevelopments,
  getAdvisors,
  getGuards,
  getApi,
  createAdvisorByGuard,
  editAdvisorByGuard,
  checkExistGuard,
  deleteAdvisorByGuard,
};
