import React from 'react';
import PropTypes from 'prop-types';

import logo from 'Assets/logo.png';


import { WrapperLoader, Image, LoaderOverDelai } from './Loader.styled'

const Loader = ({ loading, children }) => {
    return (
        <WrapperLoader className="loader">
            <React.Fragment>
                {loading && (
                    <LoaderOverDelai className="loader-overlay" data-testid="overlay">
                        {<Image className="loader-logo" width="100" src={logo} alt="logo" />}
                    </LoaderOverDelai>
                )}
                {children}
            </React.Fragment>
        </WrapperLoader>
    );
};

Loader.propTypes = {
    loading: PropTypes.bool,
};

Loader.defaultProps = {
    loading: false,
};

export default Loader;
