/* stylelint-disable length-zero-no-unit */
import styled, { keyframes } from 'styled-components';

import colors from 'Utils/Colors';

const contentFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const contentFadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-2rem);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`;

const Backdrop = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(247, 212, 234, 0.28);
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  transition: visibility 0.3s linear;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    & {
      backdrop-filter: blur(6px);
    }
  }
`;

const Container = styled.div`
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ size }) => size};
  margin: auto auto;
  background-color: ${({ bodyBackground }) => bodyBackground};
  border-radius: 6px;
  animation: ${({ isShow }) => (isShow ? contentFadeIn : contentFadeOut)} 0.2s linear;
  box-shadow: 0px 4px 12px rgba(118, 127, 155, 0.12);
`;

Content.displayName = 'Content';

const CloseButton = styled.button`
  display: inline-flex;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1;
  color: ${colors.darkGrey};
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: none;
`;

CloseButton.displayName = 'CloseButton';

const Header = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: ${colors.darkGrey};
  padding: 0.625rem;
  line-height: 1.5;
  font-size: 2.125rem;
  font-family: 'Montserrat', sans-serif;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 1.563rem;
`;

const Body = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: ${({ padding }) => padding}rem;
  color: ${colors.darkGrey};
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.625rem;
`;

export { Wrapper, Backdrop, Container, Content, CloseButton, Header, Body, Footer };