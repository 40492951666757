import * as type from '../types';

const initialState = {
    events: [],
    developments: [],
    advisors: [],
    guards: [],
    checkGuard: {},
    loading: false,
    error: null,
    getAll: false
}

export default function readEvents(state = initialState, action) {
    switch (action.type) {
        case type.GET_EVENTS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.users.items
            }
        case type.GET_EVENTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case type.GET_DEVELOPMENTS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_DEVELOPMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                developments: action.developments.items
            }
        case type.GET_DEVELOPMENTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case type.GET_ADVISORS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ADVISORS_SUCCESS:
            return {
                ...state,
                loading: false,
                advisors: action.advisors.items
            }
        case type.GET_ADVISORS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }


        case type.GET_GUARDS_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_GUARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                guards: action.guards.items,
            }
        case type.GET_GUARDS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case type.POST_ADVISORY_GUARD_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.POST_ADVISORY_GUARD_SUCCESS:
            return {
                ...state,
                loading: false,
                getAll: true
            }
        case type.POST_ADVISORY_GUARD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case type.EDIT_ADVISORY_GUARD_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.EDIT_ADVISORY_GUARD_SUCCESS:
            return {
                ...state,
                loading: false,
                getAll: true
            }
        case type.EDIT_ADVISORY_GUARD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }


        case type.CHECK_GUARD_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.CHECK_GUARD_SUCCESS:
            return {
                ...state,
                loading: false,
                checkGuard: action.checkGuard
            }
        case type.CHECK_GUARD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }


        case type.DELETE_ADVISORY_GUARD_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.DELETE_ADVISORY_GUARD_SUCCESS:
            return {
                ...state,
                loading: false,
                checkGuard: action.checkGuard,
                getAll: true
            }
        case type.DELETE_ADVISORY_GUARD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }

        case type.RESET_STATE:
            return {
                ...state,
                loading: false,
                getAll: action.getAll,
            }

        default:
            return state
    }
}