import { rgba } from 'polished';

export default {
    base: '#00AEC7',
    baseLight: '#31BED2',
    baseLighter: '#7AD5E2',
    baseLightest: '#CCEFF4',

    success: '#00A764',
    successLight: '#31B882',
    successLighter: '#7AD1AE',
    successLightest: '#CCEDE0',

    error: '#F12545',
    errorLight: '#F44F69',
    errorLigher: '#F88D9F',
    errorLightest: '#FCD3DA',
    errorPinkLightest: '#F5CDE3',

    warning: '#FFBF00',
    warningLight: '#FFCB31',
    warningLighter: '#FFDE7A',
    warningLightest: '#FFF2CC',

    notification: '#E31C79',
    notificationLight: '#DF57AC',
    notificationLighter: '#EA93C9',
    notificationLightest: '#F7D5EA',
    notificationOffer: '#D72F98',

    label: '#6E7486',
    labelLight: '#9B9FAB',
    labelLighter: '#D0D1D7',
    labelLightest: '#E2E3E7',

    inactive: '#B7BCC9',
    inactiveDropdown: rgba(183, 188, 201, 0.15),

    divider: '#767F9B',

    black: '#000000',
    white: '#FFFFFF',
    transparent: 'transparent',

    darkGrey: '#333333',
    darkGrey_2: '#525252',
    darkGrey_3: '#53565A',

    midGrey: '#666666',
    midGrey_2: '#959595',
    midGrey_3: '#C4C4C4',

    backgroundGrey: '#CDCED9',
    backgroundGrey_2: '#E4E4EA',
    backgroundGrey_3: '#F3F3F7',
    backgroundGrey_4: '#F2F5FF',
    backgroundGrey_5: '#DADBE3',
    backgroundGrey_6: '#F7F7FB',
    backgroundGrey_7: '#ECECEC',
    backgroundGrey_8: '#F3F3F6',

    disabled: '#B2B8C8',
    gradient: '#F7D5EA',
    shadow: '#8690ae',
    innerBorder: '#F4F5F7',

    greenyellow: '#97D700',
    forestgreen: '#00B140',
    darkorange: '#FF6900',
    darkblue: '#1E22AA',
    darkmagenta: '#9B26B6',
    lightskyblue: '#62B5E5',
    gold: '#FFCD00',
    greenWhatsapp: '#25D366',
    notification60: '#EC75AE',
};