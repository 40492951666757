import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { lowerCase, generateStartDate, generateEndDate } from "Utils/Utils";
import useCommonFocusEffect from "Utils/useCommonFocusEffect";

export const formattingGuards =
  ({ guards, setGuardsFormat }) =>
    () => {
      let format = [];
      guards?.map((item) => {
        format.push({
          id: item.id,
          title: `${item.development}, ${item.name} ${item.last_name}, ${item.guard_shift}`,
          allDay: false,
          start: generateStartDate(item),
          end: generateEndDate(item),
        });
      });
      setGuardsFormat(format);
    };

export const initialEffect =
  ({ readGuards, development, startDate }) =>
    () => {
      readGuards({
        development,
        start_date: moment(startDate).startOf("month").format("YYYY-MM-DD"),
        end_date: moment(startDate).endOf("month").format("YYYY-MM-DD"),
      });
    };

const useHomePage = ({
  readGuards,
  guards,
  getAll,

  readAdvisors,
  readDevelopments,
  postAdvisoryByGuard,
  editAdvisoryByGuard,
  checkExistGuard,
  checkGuard,
  deleteAdvisoryByGuard,
  resetState
}) => {
  const [role, setRole] = useState("");
  const [guardsFormat, setGuardsFormat] = useState([]);
  const [development, setDevelopment] = useState("");
  const [tittleModal, setTittleModal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteGuard, setDeleteGuard] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));

  const data = {
    advisor_bitrix_id: "",
    name: "",
    last_name: "",
    development_bitrix_id: "",
    development: "",
    date_guard: "",
    guard_shift: "",
    deleted_guard_id: "",
    id: "",
  };
  const [addTurn, setAddTurn] = useState(data);

  const handleSendRequest = () => {
    const { date_guard, development, guard_shift, advisor_bitrix_id } = addTurn;
    if (development == "" || guard_shift == "" || advisor_bitrix_id == "") {
      setMessage("Todos los campos son obligatorios");
    } else {
      if (date_guard == "") {
        addTurn.date_guard = moment(new Date()).format("YYYY-MM-DD");
      }
      if (isEdit) {
        handleEditGuard(addTurn);
      } else {
        postAdvisoryByGuard(addTurn);
      }
      setIsOpen(false);
    }
  };
  const clearData = () => {
    setAddTurn(data);
    setMessage("");
    setIsEdit(false);
    setDeleteGuard(false);
    setEditConfirm(false);
    resetState({ getAll: false })
  };

  const handleDeleteGuard = () => {
    setMessage("Esta seguro de borrar esta guardia ?");
    setDeleteGuard(true);
  };

  const handleSendDeleteGuard = () => {
    deleteAdvisoryByGuard({ id: addTurn.id });
    clearData();
    setIsOpen(false);
  };

  const handleEditInfo = (event) => {
    setIsCreate(false);
    setTittleModal("DETALLES DE GUARDIA");
    const filter = guards.filter((item) => item.id == event.id);
    setAddTurn({
      ...filter[0],
    });
    setIsOpen(true);
  };
  const handleConfirmEdit = () => {
    if (checkGuard.items.length > 0) {
      addTurn.deleted_guard_id = checkGuard.items[0].id;
      editAdvisoryByGuard(addTurn);
    } else {
      postAdvisoryByGuard(addTurn);
    }

    clearData();
    setIsOpen(false);
  };

  const handleEditGuard = () => {
    checkExistGuard({
      development_bitrix_id: addTurn.development_bitrix_id,
      date_guard: addTurn.date_guard,
      guard_shift: addTurn.guard_shift,
    });
    setEditConfirm(true);
  };

  useEffect(() => {
    setMessage(checkGuard?.message);
  }, [checkGuard]);

  const editTrusted = () => {
    setIsEdit(true);
    setIsCreate(true);
  };
  useCommonFocusEffect(initialEffect({ readGuards, development, startDate }), [
    development,
    getAll,
    startDate
  ]);
  useCommonFocusEffect(formattingGuards({ guards, setGuardsFormat }), [guards]);

  useEffect(() => {
    readAdvisors();
    readDevelopments();
  }, []);

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var rol = url.searchParams.get("rol");

    var url_string = window.location.href;
    var url = new URL(url_string);
    var position = url.searchParams.get("posicion");

    if (rol == 60 || rol == 51 || position == "GERENTE_DE_VENTAS") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  return {
    addTurn,
    guardsFormat,
    tittleModal,
    development,
    isOpen,
    isCreate,
    isEdit,
    message,
    role,
    deleteGuard,
    editConfirm,

    setDevelopment,
    setAddTurn,
    setTittleModal,
    setIsOpen,
    handleSendRequest,
    handleEditInfo,
    clearData,
    setIsCreate,
    editTrusted,
    handleEditGuard,
    handleSendDeleteGuard,
    handleDeleteGuard,
    handleConfirmEdit,
    setStartDate,
  };
};

export default useHomePage;
