import styled from 'styled-components';
import colors from 'Utils/Colors';

const WrapperCustomToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
`;

const WrapperIconsLeft = styled.div`
    display: flex;
`;
const WrapperIconsRigth = styled.div`
    display: flex;
`;
const WrapperIconsCenter = styled.div`
    display: flex;

`;
const Button = styled.button`
    padding: 0.625rem;
    background-color: ${({ active }) => active ? colors.darkGrey : colors.error};
    border-radius: 0.25rem;
    color: ${colors.white};
    font-size: 1rem;
    font-weight: bold;
    border-color: ${({ transparent }) => transparent ? 'transparent' : colors.darkGrey};
`;

const ButtonToday = styled.button`
    padding: 0.625rem;
    margin-left: 0.625rem;
    background-color: ${({ color }) => color ? colors[color] : colors.error};
    border-radius: 0.25rem;
    color: ${colors.white};
    font-size: 1rem;
    font-weight: bold;
    border-color: transparent;
`;
const Label = styled.label`
    font-size: 2rem;
`;


export {
    WrapperCustomToolbar,
    WrapperIconsRigth,
    WrapperIconsLeft,
    WrapperIconsCenter,
    Button,
    Label,
    ButtonToday
};