import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";

import MyCalendar from "Components/Calendar";
import Header from "Components/Header";
import RenderModal from "Views/PagesComponents/RendernModal";
import useHomePage from "./useHomePage";
import { Wrapper } from "./Home.styled";
import Loader from "Components/Loader";

import {
  getDevelopments,
  getAdvisors,
  getGuards,
  postAdvisoryByGuard,
  editAdvisoryByGuard,
  checkExistGuard,
  deleteAdvisoryByGuard,
  resetState,
} from "State/actions/events";
import "react-toastify/dist/ReactToastify.css";

const App = ({
  readGuards,
  readDevelopments,
  readAdvisors,
  postAdvisoryByGuard,
  editAdvisoryByGuard,
  checkExistGuard,
  deleteAdvisoryByGuard,

  developments,
  advisors,
  guards,
  checkGuard,
  loading,
  getAll,
}) => {
  const {
    guardsFormat,
    addTurn,
    tittleModal,
    isOpen,
    isCreate,
    isEdit,
    message,
    role,
    deleteGuard,
    editConfirm,

    setStartDate,
    handleDeleteGuard,
    setDevelopment,
    setAddTurn,
    handleSendRequest,
    setTittleModal,
    setIsOpen,
    handleEditInfo,
    clearData,
    setIsCreate,
    editTrusted,
    handleEditGuard,
    handleConfirmEdit,
    handleSendDeleteGuard,
  } = useHomePage({
    readGuards,
    guards,
    readAdvisors,
    readDevelopments,
    postAdvisoryByGuard,
    editAdvisoryByGuard,
    checkExistGuard,
    checkGuard,
    deleteAdvisoryByGuard,
    loading,
    getAll,
    resetState,
  });

  return (
    <Wrapper>
      <ToastContainer />
      <Loader loading={loading}>
        <Header
          developments={developments}
          setIsOpen={setIsOpen}
          setDevelopment={setDevelopment}
          setTittleModal={setTittleModal}
          clearData={clearData}
          setIsCreate={setIsCreate}
          role={role}
        />
        <RenderModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          developments={developments}
          advisors={advisors}
          setAddTurn={setAddTurn}
          addTurn={addTurn}
          handleSendRequest={handleSendRequest}
          tittleModal={tittleModal}
          isCreate={isCreate}
          editTrusted={editTrusted}
          isEdit={isEdit}
          handleEditGuard={handleEditGuard}
          handleConfirmEdit={handleConfirmEdit}
          clearData={clearData}
          message={message}
          editConfirm={editConfirm}
          deleteGuard={deleteGuard}
          handleDeleteGuard={handleDeleteGuard}
          handleSendDeleteGuard={handleSendDeleteGuard}
          role={role}
        />
        <MyCalendar
          myEventsList={guardsFormat}
          setIsOpen={setIsOpen}
          setTittleModal={setTittleModal}
          handleEditInfo={handleEditInfo}
          setStartDate={setStartDate}
        />
      </Loader>
    </Wrapper>
  );
};

App.defaultProps = {
  developments: undefined,
  advisors: undefined,
  guards: undefined,
  getAll: undefined,
};

App.propTypes = {
  developments: PropTypes.array,
  advisors: PropTypes.array,
  guards: PropTypes.array,
  readDevelopments: PropTypes.func.isRequired,
  readAdvisors: PropTypes.func.isRequired,
  readGuards: PropTypes.func.isRequired,
  postAdvisoryByGuard: PropTypes.func.isRequired,
  editAdvisoryByGuard: PropTypes.func.isRequired,
  checkExistGuard: PropTypes.func.isRequired,
  deleteAdvisoryByGuard: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  developments: state.readEvents.developments,
  advisors: state.readEvents.advisors,
  guards: state.readEvents.guards,
  checkGuard: state.readEvents.checkGuard,
  loading: state.readEvents.loading,
  getAll: state.readEvents.getAll,
});

const mapDispatchToProps = (dispatch) => ({
  readDevelopments: () => dispatch(getDevelopments()),
  readAdvisors: () => dispatch(getAdvisors()),
  resetState: () => dispatch(resetState()),
  readGuards: ({ development, start_date, end_date }) =>
    dispatch(
      getGuards({
        development,
        start_date,
        end_date,
      })
    ),
  checkExistGuard: ({ development_bitrix_id, date_guard, guard_shift }) =>
    dispatch(
      checkExistGuard({
        development_bitrix_id,
        date_guard,
        guard_shift,
      })
    ),

  postAdvisoryByGuard: ({
    advisor_bitrix_id,
    name,
    last_name,
    development_bitrix_id,
    development,
    date_guard,
    guard_shift,
  }) =>
    dispatch(
      postAdvisoryByGuard({
        advisor_bitrix_id,
        name,
        last_name,
        development_bitrix_id,
        development,
        date_guard,
        guard_shift,
      })
    ),
  editAdvisoryByGuard: ({
    advisor_bitrix_id,
    name,
    last_name,
    development_bitrix_id,
    development,
    date_guard,
    guard_shift,
    deleted_guard_id,
  }) =>
    dispatch(
      editAdvisoryByGuard({
        advisor_bitrix_id,
        name,
        last_name,
        development_bitrix_id,
        development,
        date_guard,
        guard_shift,
        deleted_guard_id,
      })
    ),
  deleteAdvisoryByGuard: ({ id }) =>
    dispatch(
      deleteAdvisoryByGuard({
        id,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
