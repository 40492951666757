import { call, put, takeEvery } from 'redux-saga/effects'
import * as type from '../types';
import { toast } from 'react-toastify';

import {
    getApi,
    getDevelopments,
    getAdvisors,
    getGuards,
    createAdvisorByGuard,
    editAdvisorByGuard,
    checkExistGuard,
    deleteAdvisorByGuard
} from 'Services'


function* fetchEvents(action) {
    try {
        const events = yield call(getApi);
        yield put({ type: type.GET_EVENTS_SUCCESS, events: events });
    } catch (e) {
        yield put({ type: type.GET_EVENTS_FAILED, message: e.message });
    }
}

function* eventSaga() {
    yield takeEvery(type.GET_EVENTS_REQUESTED, fetchEvents);
}

function* fetchDepartaments(action) {
    try {
        const developments = yield call(getDevelopments);
        yield put({ type: type.GET_DEVELOPMENTS_SUCCESS, developments: developments });
    } catch (e) {
        yield put({ type: type.GET_DEVELOPMENTS_FAILED, message: e.message });
    }
}

function* eventSagaDepartaments() {
    yield takeEvery(type.GET_DEVELOPMENTS_REQUESTED, fetchDepartaments);

}

function* fetchAdvisors(action) {
    try {
        const advisors = yield call(getAdvisors);
        yield put({ type: type.GET_ADVISORS_SUCCESS, advisors: advisors });
    } catch (e) {
        yield put({ type: type.GET_ADVISORS_FAILED, message: e.message });
    }
}

function* eventSagaAdvisors() {
    yield takeEvery(type.GET_ADVISORS_REQUESTED, fetchAdvisors);

}

function* fetchGuards(action) {
    try {
        const guards = yield call(getGuards, action.payload);
        yield put({ type: type.GET_GUARDS_SUCCESS, guards: guards });
    } catch (e) {
        yield put({ type: type.GET_GUARDS_FAILED, message: e.message });
        toast.error(e.message);
    }
}

function* eventSagaGuards() {
    yield takeEvery(type.GET_GUARDS_REQUESTED, fetchGuards);

}

function* eventCreateAdvisorByGuard(action) {
    try {
        const guards = yield call(createAdvisorByGuard, action.payload);
        yield put({ type: type.POST_ADVISORY_GUARD_SUCCESS, guards: guards });
        yield put({ type: type.RESET_STATE, getAll: false });
        toast.success(guards.message);
    } catch (e) {
        yield put({ type: type.POST_ADVISORY_GUARD_FAILED, message: e.message });
        toast.error(e.response.data.message);
    }
}

function* eventSagaPostAdvisoryByGuard() {
    yield takeEvery(type.POST_ADVISORY_GUARD_REQUESTED, eventCreateAdvisorByGuard);
}

function* eventEditAdvisorByGuard(action) {
    try {
        const guards = yield call(editAdvisorByGuard, action.payload);
        yield put({ type: type.EDIT_ADVISORY_GUARD_SUCCESS, guards: guards });
        yield put({ type: type.RESET_STATE, getAll: false });
        toast.success(guards.message);
    } catch (e) {
        yield put({ type: type.EDIT_ADVISORY_GUARD_FAILED, message: e.message });
        toast.error(e.message);
    }
}

function* eventSagaEditAdvisoryByGuard() {
    yield takeEvery(type.EDIT_ADVISORY_GUARD_REQUESTED, eventEditAdvisorByGuard);

}

function* eventCheckExisteGuard(action) {
    try {
        const checkGuard = yield call(checkExistGuard, action.payload);
        yield put({ type: type.CHECK_GUARD_SUCCESS, checkGuard: checkGuard });
    } catch (e) {
        yield put({ type: type.CHECK_GUARD_FAILED, message: e.message });
        toast.error(e.message);
    }
}
function* eventSagaCheckGuard() {
    yield takeEvery(type.CHECK_GUARD_REQUESTED, eventCheckExisteGuard);

}

function* eventDeleteAdvisorByGuard(action) {

    try {
        const deleteGuard = yield call(deleteAdvisorByGuard, action.payload);
        yield put({ type: type.DELETE_ADVISORY_GUARD_SUCCESS, deleteGuard: deleteGuard });
        yield put({ type: type.RESET_STATE, getAll: false });
        toast.success(deleteGuard.message);
    } catch (e) {
        yield put({ type: type.DELETE_ADVISORY_GUARD_FAILED, message: e.message });
        toast.error(e.response.data.message);
    }
}

function* eventSagaDeleteAdvisoryByGuard() {
    yield takeEvery(type.DELETE_ADVISORY_GUARD_REQUESTED, eventDeleteAdvisorByGuard);

}


export {
    eventSaga,
    eventSagaDepartaments,
    eventSagaAdvisors,
    eventSagaGuards,
    eventSagaPostAdvisoryByGuard,
    eventSagaEditAdvisoryByGuard,
    eventSagaCheckGuard,
    eventSagaDeleteAdvisoryByGuard
}